import React, { useState } from "react";
import axios from "axios";

function Chat() {
  const [mesajlar, setMesajlar] = useState(null);
  const [show,setShow] = useState(false)
  useState(() => {
    const interval = setInterval(() => {
      if(show){
        getMesajlar();
      }
    }, 1000);
    getMesajlar();
    return () => clearInterval(interval);
  }, []);


  function getMesajlar() {
    axios.get("/api/mesajlar/" + localStorage.pid).then((res) => {
      setMesajlar(res.data);
      var objDiv = document.querySelector(".mesajlar");
      objDiv.scrollTop = objDiv.scrollHeight
    });
  }

  function sendMessage(e) {
    e.preventDefault();
    const msj = e.target[0];
    const btn = e.target[1];
    if (msj.value != "") {
      btn.innerHTML = "<i class='fa fa-spin fa-spinner'></i>";
      axios
        .post("/api/sendMesaj", {
          sender_id: localStorage.reel_id,
          pid: localStorage.pid,
          mesaj: msj.value,
        })
        .then((res) => {
          msj.value = "";
          btn.innerHTML = "Gönder";
          getMesajlar()
        });
    }
  }
 return( 
   show ?
    <div className="chat-bg gir">
      <div className="cubuk" onClick={e=>setShow(false)}>
        <i className="fa fa-caret-right fs-5 text-mavi"></i>
      </div>
      <div className="chat-con p-3">
        <div className="bg-gri br px-2 fs-4 fw-500 text-mavi">
          Canlı Mesajlaşma{" "}
        </div>
        <div className="mesajlar">
          {mesajlar &&
            mesajlar.map((val) => {
              return (
                <div
                  className={
                    val.sender_id == localStorage.reel_id
                      ? "karsi-mesaj benim-mesaj"
                      : "karsi-mesaj"
                  }
                >
                  {val.sender_id == localStorage.reel_id ? (
                    <>
                      <div className="mesaj px-2">
                        <div className="isim fw-500 text-mavi">{val.isim}</div>
                        <div className="mesajx">{val.mesaj}</div>
                        <div className="isim fw-500 opacity-75 text-end">
                          {val.tarih}
                        </div>
                      </div>
                      <div className="img">
                        <img  onError={e=>{
                      e.target.src = axios.defaults.baseURL+ "/assets/img/user/default.png"
                    }}
                  src={axios.defaults.baseURL + "/assets/img/user/" +val.pp}
                       
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="img">
                        <img
  onError={e=>{
    e.target.src = axios.defaults.baseURL+ "/assets/img/user/default.png"
  }}
src={axios.defaults.baseURL + "/assets/img/user/" + val.pp}                          alt=""
                        />
                      </div>
                      <div className="mesaj px-2">
                        <div className="isim fw-500 text-mavi">{val.isim}</div>
                        <div className="mesajx">{val.mesaj}</div>
                        <div className="isim fw-500 opacity-75 text-end">
                          {val.tarih}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>{" "}
        <form onSubmit={(e) => sendMessage(e)} className="msg-box mt-2">
          <div className="row gx-1">
            <div className="col-9">
              <input
                placeholder="Mesajınızı Giriniz"
                type="text"
                className="d-block w-100 emr-input"
              />
            </div>
            <div className="col-3">
              <button className="button button-mavi flex-fill w-100">
                Gönder{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>  : <div onClick={e=>setShow(true)} className="gir chat-box bg-mavi br"  ><i className="fa-solid fa-message"></i>Mesajlar</div>
  )
}

export default Chat;