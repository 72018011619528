import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from "axios"

// axios.defaults.baseURL = "http://localhost/map-backend"
axios.defaults.baseURL = "https://back2.orkestrasefi.com"


ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);