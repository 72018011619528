import axios from 'axios';
import React,{useState,useEffect} from 'react'

function Rapor({mid}) {
  const [data, setData] = useState(null);
  useEffect(()=>{
    axios.get("/api/rapor/"+mid).then(res=>{
        console.log(res.data)
        setData(res.data)
    })
  },[])
  return (
    <div className='gir pencere-bg'>
        <div className="pencere">
        <div className="pencere__header">
          <div className="sol text-mavi fw-500">Ziyaret Raporu</div>
        </div>
       
        <div className="bg-sec bb-8 p-3">
            <div className="row g-3">

                {data && data.map(val=>{
                    return(
                        <div className="col-lg-4" >
                        <div className="zrapor position-relative br p-2 bg-white">
                           <div className="foto">
                               <img src={axios.defaults.baseURL+"/assets/img/user/"+val.pp} alt="" />
                            </div>
                            <div className='ms-2' >
                            <div className="fw-bold ">{val.isim}</div>
                            <div className="opacity-75 lh-1"><span className="text-mavi fw-bold">{val.toplam}</span> Kere ziyaret etti</div>
                            <div style={{bottom:5,right:5}} className="son position-absolute text-end fw-500 text-mavi">
                                Son Ziyaret:  {new Date(val.son).getDate() +
                        "." +
                        (new Date(val.son).getMonth() + 1) +
                        "." +
                        new Date(val.son).getFullYear()}
                            </div>
                            </div> 
                        </div>
                    </div>
                    )
                })}



            </div>
        </div>
      </div>
    </div>
  )
}

export default Rapor