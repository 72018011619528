import React, { useState, useEffect } from "react";
import mapProps from "../mapProps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import {
  GoogleMap,
  withScriptjs,
  Marker,
  withGoogleMap,
  Circle,
  Polyline,
} from "react-google-maps";
import axios from "axios";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    const [lat, setLat] = useState(props.p1);
    const [lng, setLng] = useState(props.p2);
    return (
      <GoogleMap
        key={0}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          fullscreenControl: false,
          styles: props.styles,
        }}
        id="example-map"
        defaultZoom={12}
        defaultCenter={{ lat: lat, lng: lng }}
      >
        {props.yerler}
      </GoogleMap>
    );
  })
);

function Index({ setSideMode, setInfo, mode, i }) {
  const [themeIndex, setThemeIndex] = useState(i);
  const [yerler, setYerler] = useState(null);
  const url = new URLSearchParams(window.location.search);
  useEffect(() => {
    axios
      .get(
        `/api/getYerler/${localStorage.pid}?type=${
          url.get("type") ? url.get("type") : "no"
        }&ziyaret=${url.get("ziyaret") ? url.get("ziyaret") : "no"}&grup=${url.get("grup") ? url.get("grup") : "no"}`
      )
      .then((res) => {
        setYerler(res.data);
      });

  }, []);
  return (
    <div className="all">
      {
        url.get("grup") ||
        url.get("ziyaret") ||
        url.get("type") ? 
     
      <div className="filtre-mesaj">
        <div className="box ">
          <div className="fw-500 px-5">
          { url.get("grup") ? "Grup Filtresi Gösteriliyor" : "" }
          { url.get("ziyaret") && url.get("ziyaret") == "0" ? "Hiç Ziyaret Edilmeyenler Gösteriliyor" : "" }
          { url.get("type") && url.get("type") == "0" ? "Özel Müşteriler Gösteriliyor" : "" }
          { url.get("type") && url.get("type") == "1" ? "Kurumsal Müşteriler Gösteriliyor" : "" }
      
          </div>
          <a href="/" className="text-beyaz lh-1 d-block text-decoration-none text-center opacity-75 py-2">Temizle</a>
        </div>
      </div>

       
       : "" }
      {yerler != null ? (
        <MyMapComponent
          isMarkerShown
          isRadius
          styles={mapProps[i]}
          isRoad
          musteriSelect={(e) => {
            console.log(e);
            setSideMode(9999);
            setSideMode(1);
          }}
          p1={38.4161411934773}
          p2={27.018369774704905}
          yerler={
            mode == 0 ? (
              yerler.map((yer) => {
                return yer.ilceler.map((ilce) => {
                  return ilce.musteriler.map((m) => {
                    return (
                      <div key={m.ilce_id}>
                      
                        <Marker
                          onClick={(e) => {
                            console.log(m.ilce_id);

                            setInfo(m.ilce_id);
                          }}
                          icon={"/marker.png"}
                          position={{
                            lat: Number(m.lat),
                            lng: Number(m.lng),
                          }}
                        />
                        <InfoBox
                          options={{
                            closeBoxURL: "",
                            enableEventPropagation: true,
                          }}
                          position={{
                            lat: Number(m.lat),
                            lng: Number(m.lng),
                          }}
                        >
                          <div
                            onClick={(e) => {
                              console.log("s");

                              console.log(m.musteri_id);
                              setInfo(m.musteri_id);
                            }}
                            className="tss"
                          >
                            <div
                              style={{
                                fontSize: 20,
                                display: "flex",
                                fontWeight: "bold",
                                alignItems: "center",
                              }}
                            >
                              {m.name} ({m.ziyaret})
                            </div>
                          </div>
                        </InfoBox>
                      </div>
                    );
                  });
                });
              })
            ) : (
              <></>
            )
          }
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQCTcVjWj-hwAAmEAq74482WXYKiFG1v8&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      ) : (
        ""
      )}
    </div>
  );
}
                
export default Index;