import React from 'react'

function Box({title,onCancel,onConfirm}) {
  return (
    <div  className='confirm-box gir bordered shadow-sm'>
        <div className="fw-500">{title}</div>
        <div className="d-flex mt-3">
            <button onClick={e=>onConfirm(e)} className="button button-mavi px-2 flex-fill">Onayla</button>
            <button onClick={e=>onCancel()} className="button button-gri ms-2 px-2 flex-fill">İptal</button>
        </div>
    </div>
  )
}

export default Box