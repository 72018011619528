import React, { useEffect } from 'react'

function Out() {

    useEffect(()=>{
        localStorage.removeItem("pid")
          window.location = "/"
    },[])
  return (
    <div>Out</div>
  )
}

export default Out