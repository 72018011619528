import axios from "axios";
import React, { useState, useEffect } from "react";
import Box from "../components/Box";

function Departman() {
  const [checkedState, setCheckedState] = useState(new Array(5).fill(false));
const [data,setData] = useState(null);
const [index, setIndex] = useState(null)
const [ekleMode, setEkleMode] = useState(false)
const [show,setShow] = useState(false)
const [deleteIndex, setDeleteIndex] = useState(null);
const [realIndex, setRealIndex] = useState(null)
  const handleOnChange = (e) => {
      console.log(e.target.checked)
    if(e.target.checked){
        e.target.checked = true

    }else{

    e.target.checked = false

    }
    };

  useEffect(()=>{
      axios.get("/api/departmanList/"+localStorage.pid).then(res=>{
          setData(res.data)

      })
  },[])

  useEffect(()=>{
    document.querySelectorAll(".form-check-input").forEach((el,x)=>{
      el.checked = data[index].yetki.split(",")[x] == "true" ? true : false;
      console.log(data[index].yetki)
      })
  },[index])
  return (
    <div className="gir users-popup">
        {show ? 
        <Box
        title={"Departmanı Silmek İstediğinize Emin Misiniz"}
        onCancel={()=>{setShow(false)}}
        onConfirm={(e)=>{
            e.target.innerHTML = "<i class='fa fa-spin fa-spinner'></i>"
            axios.post("/api/deleteDepartman/"+deleteIndex,{}).then(res=>{  
                setData(null);
                setIndex(null);
                setShow(false)
               axios.get("/api/departmanList/"+localStorage.pid).then(res2=>{
                    setData(res2.data)
                })
            })
        }}
        />
      : "" }<div className="d-flex flex-column justify-content-between">
        <div className="text-mavi fw-500 fs-4 lh-1">Departman Yönetimi</div>
        <p className="text-white">Departmanları düzenleme ve ekleme paneli</p>
      </div>

      <div className="row mt-4">
        <div className="col-lg-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-500 fs-5">Departmanlar</div>
            {!ekleMode ? 

<button onClick={e=>setEkleMode(true)} className="button button-mavi px-2">
{" "}
<i className="fa fa-plus"></i> Ekle
</button>
: 
<button onClick={e=>setEkleMode(false)} className="button button-mavi px-2">
              {" "}
              <i className="fa fa-cancel"></i> İptal
            </button>
            
        }
            
          </div>
          {ekleMode ? 
          <form onSubmit={e=>{
              e.preventDefault()
              if(e.target[0].value != ""){

             
              const t = e.target[1];
              const i = e.target[0]; 
              t.innerHTML = "<i class='fa fa-spinner fa-spin'></i>";
              axios.post("/api/addDepartman",{
                  isim: e.target[0].value,
                  pid: localStorage.pid
              }).then(res=>{
                  t.innerHTML = "<i class='fa fa-plus'></i> Ekle";
                  i.value = ""
                  axios.get("/api/departmanList/"+localStorage.pid).then(res2=>{
                    setData(res2.data)
                })
              })
            }
          }}>
              <div className="row">
                  <div className="col-8">
                  <div className="fg">
                  <label className="fw-500 text-mavi">Pozisyon</label>
              <input type="text" className="emr-input" />

              </div>
                  </div>
                  <div className="col-4 d-flex flex-column justify-content-end">
                      <button className="button button-mavi px-2">Ekle</button>
                  </div>
              </div>
              
          </form> : "" }
            
          <ul className="deps mt-2">
            {
                 data && data.map((val,i)=>{
                    return(
                      <li className="d-flex justify-content-between align-items-center" onClick={e=>{
                         console.log("31111")
                         setIndex(i)
                            setRealIndex(val.departman_id)
                            
                           
                          }} key={val.departman_id}>
                        <div><i className="fa-solid fa-circle-dot"></i> {val.isim}</div>
                        <i onClick={e=>{
                            setIndex(null)
                            setDeleteIndex(val.departman_id)
                            setShow(true)
                        }} className="fa fa-trash text-mavi"></i>
                      </li>
                    )
                })
            }
          </ul>
        </div>
        {
            index !=null && data !=null ?

            
        
        <div className="col-lg-6">
          
          <div className="yetkiler">
            <div className=" fw-500 fs-5 ">{data[index].isim} için Yetkiler</div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Ekleme"
                
                onChange={(e) => handleOnChange(e)}
              />
              <label className="form-check-label text-white">
                Müşteri Ekleme
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
                
                onChange={(e) => handleOnChange(e)}
              />
              <label className="form-check-label text-white">
                Müşteri Düzenleme
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
             
                onChange={(e) => handleOnChange(e)}
              />
              <label className="form-check-label text-white">
                Geçmiş Ziyaretler Düzenleme
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
               
              
                onChange={(e) => handleOnChange(e)}
              />
              <label className="form-check-label text-white">
                Geçmiş Ziyaretler Silme
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
              
                onChange={(e) => handleOnChange(e)}
              />
              <label className="form-check-label text-white">
                Geçmiş Ziyaretler Ekleme
              </label>
            </div>
            {
            
          }
            <button className="button button-mavi px-2 mt-2" onClick={e=>{
                const s  = [];
                const c = document.querySelectorAll(".form-check-input")
                c.forEach((el,i)=>{
                    s[i] = el.checked
                })
                console.log(s)
                const t = e.target;
                t.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
                axios.post("/api/updateYetki/"+realIndex,{
                    yetki: s.join(",")
                }).then(res=>{
                    t.innerHTML = "Kaydet"
                })
            }}>Kaydet</button>
          </div>
        </div> : "" }
      </div>
    </div>
  );
}

export default Departman;
