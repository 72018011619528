import React from "react";
import axios from "axios"
function ZiyaretEkle({mid,eklendi}) {

  function submit(e) {
      e.preventDefault();
      const t = document.querySelector("#send")
      t.innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
      axios.post("/api/ziyaretEkle/",{ 
          to_id: mid,
          user_id: localStorage.reel_id,
          ziyaretci: e.target[0].value,
          gk: e.target[1].value,
          gky: e.target[2].value,
          gf: e.target[3].value,
          tarih: e.target[4].value,
          note: e.target[5].value,
       
      }).then(res=>{
        console.log(res)
        t.innerHTML = '<i class="fa fa-plus"></i> Ekle'
        eklendi()
      })
  }
  return (
    <div className="gir pencere-bg">
   
      <div className="pencere">
        <div className="pencere__header ">
          <div className="sol text-mavi fw-500">Ziyaret Ekle</div>
        </div>
       
        <div className="bg-sec bb-8 p-3">
          <form onSubmit={(e) => submit(e)}>
            <div className="row">
              <div className="col-lg-5">
                <div className="fg">
                  <label>Ziyaretçi</label>
                  <input disabled style={{userSelect:"none !important"}} defaultValue={localStorage.isim} type="text" className="emr-input" />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-5">
                <div className="fg">
                  <label>Görüşülen Kişi</label>
                  <input type="text" className="emr-input" />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-5">
                <div className="fg">
                  <label>Görüşülen Kişi Yetkisi</label>
                  <input type="text" className="emr-input" />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-5">
                <div className="fg">
                  <label>Görüş Firması</label>
                  <input type="text" className="emr-input" />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-5">
                <div className="fg">
                  <label>Tarih</label>
                  <input type="date" className="emr-input" />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-5">
                <div className="fg">
                  <label>Ziyaret Notu</label>
                  <input type="text" className="emr-input" />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-5">
                <button id="send" className="button button-mavi fw-bold w-100 d-block px-2">
                  <i className="fa fa-plus"></i> Ekle
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ZiyaretEkle;