import {
  GoogleMap,
  withScriptjs,
  Marker,
  withGoogleMap,
  Circle,
  Polyline,
} from "react-google-maps";
import { useState, useEffect, useRef, Fragment } from "react";
//import "./assets/css/style.css";
import Sidebar from "./components/Sidebar";
import mapProps from "./mapProps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

import axios from "axios";
import Image from "./components/Image";
import { BrowserRouter as Router, Routes, Route, Link, useSearchParams } from "react-router-dom";
import Camera from "./pages/Camera";
import { Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Out from "./components/Out";
import ZiyaretEkle from "./components/ZiyaretEkle";
import Rapor from "./components/Rapor";
import Departman from "./pages/Departman";
import Helmet from "react-helmet";
import Chat from "./components/Chat";
import Index from "./pages/Index";


function App() {
  const [themeIndex, setThemeIndex] = useState(0);
  const [sideMode, setSideMode] = useState(0);
  const [yerler, setYerler] = useState(null);
  const [yer, setYer] = useState(null);
  const [src, setSrc] = useState("");
  const [window, setWindow] = useState("");
  const [mode, setMode] = useState(0);
  const [ziyaretID, setZiyaretID] = useState(null);
  const [ziyaretler, setZiyaretler] = useState(null);
  const [ziyaretEkleMode, setZiyaretEkleMode] = useState(false);
  const [zListID, setZListID] = useState(null);
  const [openRapor, setOpenRapor] = useState(false)


  useEffect(() => {
    axios.get("api/getYerler/" + localStorage.getItem("pid")).then((res) => {
      setYerler(res.data);
    });
  }, []);

  useEffect(() => {
    if (ziyaretID != null) {
      axios.get(`/api/getZiyaret/${ziyaretID}`).then((res) => {
        console.log("res.data");
        console.log(res.data);
        setZiyaretler(res.data[0]);
      });
    }
  }, [ziyaretID]);

  function setInfo(i) {
    setYer(i);
    setSideMode(999);
    setSideMode(1);
  }

  function setTheme(e, i) {
    setThemeIndex(i);
    document.querySelectorAll(".selector").forEach((el) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }

  return (
    <Router>
      <Helmet>
        {localStorage.theme == "mor" ? 
        <link rel="stylesheet" href={"/css/mor.css"} />: ""        
        }

{!localStorage.theme  ? 
        <link rel="stylesheet" href={"/css/mor.css"} />: ""        
        }

{localStorage.theme == "yesil" ? 
        <link rel="stylesheet" href={"/css/yesil.css"} />: ""        
        }
      </Helmet>
      <Sidebar
        setT={(e, i) => {
          console.log("teha")
          setTheme(e, i);
        }}
        imageOpen={(e) => {
          setSrc(e);
          setWindow("image");
        }}
        openDetail={(i) => setInfo(i)}
        yer={yer}
        yerler={yerler}
        mode={sideMode}
        back={(e) => {
          setSideMode(0);
          setZiyaretID(null);
          setZiyaretEkleMode(false);
          setOpenRapor(false)

        }}
        zEkle={(e) => {
          setZiyaretID(null);
          setZiyaretEkleMode(true);
          setOpenRapor(false )

        }}
        setZiyaret={(e) => {
          setSideMode(2);
          console.log("id", e);
          setZListID(e);
        }}
        setMode={i=>setSideMode(i)}
        mid={zListID}
        setZ={(e) => {
          setZiyaretID(e);
          setOpenRapor(false)

          setZiyaretEkleMode(false);
        }}

        openRapor={e=>{
          setZiyaretID(null);
          setOpenRapor(true)
          setZiyaretEkleMode(false);

        }}
      />
      <Chat/>
      <Routes>
        <Route path="login" element={<Login />} exact />
        <Route
          element={
            localStorage.pid ? (
              <>
               
               <Index i={themeIndex} yerler={yerler} setSideMode={e=>setSideMode(e)} setInfo={e=>setInfo(e)} mode={mode}/>
              </>
            ) : (
              <Navigate replace={true} to={"/login"} />
            )
          }
          path="/"
          exact
        ></Route>

        <Route path="/departman" element={<Departman/>} exact></Route>

        <Route path="/out" element={<Out />} exact></Route>

        <Route path="/camera" element={<Camera />} exact></Route>
        <Route
          path="/users"
          element={
            <>
              <Users />
            </>
          }
        />
      </Routes>

      {ziyaretID != null ? (
        <div className="gir pencere-bg">
          <div className="pencere">
            <div className="pencere__header ">
              <div className="sol text-mavi fw-500">
                {ziyaretler && ziyaretler.tarih} Tarihli Ziyaret
              </div>
            </div>
            <div className="kisa-bar">
              <div className="kutu bg-mavi br-8">
                <div className="isim">{ziyaretler && ziyaretler.name}</div>
              </div>

              <div className="ms-auto d-flex pt-3">
                <button className="fw-500 px-2 button button-yesil me-1  ms-auto d-block">
                  <i className="fa fa-pen"></i> Düzenle
                </button>
                <button className="fw-500 px-2 button button-red me-1 d-block">
                  <i className="fa fa-trash"></i> Sil
                </button>
              </div>
            </div>

            <div className="pencere__icerik">
              <div className="row">
                <div className="col">
                  <div>
                  <label className="info">
                      Temsil Edilen Firma: <span>{ziyaretler && ziyaretler.gf}</span>
                    </label>
                    <label className="info">
                      Ziyaretçi:{" "}
                      <span>{ziyaretler && ziyaretler.ziyaretci}</span>
                    </label>
                    <label className="info">
                      Görüşülen Kişi: <span>{ziyaretler && ziyaretler.gk}</span>
                    </label>
                    <label className="info">
                      Görüşülen Kişi Yetkisi:{" "}
                      <span>{ziyaretler && ziyaretler.gky}</span>
                    </label>
                    
                    <label className="info mt-3">
                      Ziyaret Notu: <span>{ziyaretler && ziyaretler.note}</span>
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <label className="info">
                    Satın Alma Hacmi: <span>2 Kilo</span>
                  </label>
                  <label className="info">
                    Satın Alma Sıklığı: <span>Aylık</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {ziyaretEkleMode ? <ZiyaretEkle eklendi={e=>{
          setZiyaretEkleMode(false)
      }} mid={zListID} /> : ""}

{
        openRapor ? <Rapor mid={zListID}/> : "" }
      
    </Router>
  );
}


export default App;