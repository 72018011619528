import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";

function Sidebar({
  setZ,
  yer,
  mode,
  back,
  openDetail,
  imageOpen,
  setZiyaret,
  imageSrc,
  setT,
  mid,
  zEkle,
  setMode,
  openRapor,
  t,
}) {
  const [data, setData] = useState(null);
  const [tab, setTab] = useState(0);
  const [ziyaretler, setZiyaretler] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();
  const [yerler, setYerler] = useState(null);
  const [gc, setGC] = useState(null);
  const [grup, gruplandir] = useState(null);
  const url = new URLSearchParams(window.location.search);
  const [searchString, setSearchString] = useState("")
  const [searchResult, setSearchResult] = useState(null)
  const [openFilter, setOpenFilter] = useState(false);
  useEffect(() => {
    axios
      .get(
        `/api/getYerler/${localStorage.pid}?type=${
          url.get("type") ? url.get("type") : "no"
        }&ziyaret=${url.get("ziyaret") ? url.get("ziyaret") : "no"}`
      )
      .then((res) => {
        setYerler(res.data);
        console.log(res.data);
      });
  }, []);

  function changeTheme(theme) {
    localStorage.theme = theme;
    window.location.reload();
  }

  const sektorler = ["Özel", "Kurumsal"];
  useEffect(() => {
    if (tab == 1) {
      document.querySelector("#side").classList.remove("sd-mod-1");
      document.querySelector("#side").classList.add("sd-mod-3");
    } else {
      if (document.querySelector("#side")) {
        document.querySelector("#side").classList.add("sd-mod-1");
        document.querySelector("#side").classList.remove("sd-mod-3");
      }
    }
  }, [tab]);

  function setTheme(e, i) {
    //   setThemeIndex(i);
    document.querySelectorAll(".selector").forEach((el) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
  useEffect(() => {
    setData(null);
    axios.get(`api/getYer/${yer}`).then((res) => {
      const arr = res.data.foto;
      setData(res.data[0]);
    });
  }, [yer]);

  useEffect(() => {
    if (mode == 2) {
      axios.get("/api/ziyaretler/" + mid).then((res) => {
        setZiyaretler(res.data);
        console.log(res.data);
      });
    }

    if (mode == 3) {
      if (grup) {
        axios.get("/api/groupContent/" + grup).then((res) => {
          setGC(res.data);
          console.log(res.data);
        });
      }
    }
    console.log(mode);
  }, [mode]);

  useEffect(() => {
    if (data) {
      axios.get("/api/groupContent/" + data.musteri_id).then((res) => {
        setGC(res.data);
        console.log(res.data);
      });
    }
  }, [data]);

  if (localStorage.pid) {
    return (
      <div
        id="side"
        className={
          mode == 0
            ? "sidebar position-fixed  sd-mod-1"
            : "sidebar position-fixed sd-mod-2"
        }
      >
        {mode == 0 ? (
          <>
            <div className="gir kutu d-none justify-content-center align-items-center ">
              <div className="text-mavi fs-3 fw-bold">GPS MAP CAMERA</div>
            </div>

            <div className="kutu-sec p-0 mt-3 gir os">
              <div className="p-2">
                <div className="text-mavi fw-bold fs-5">Yerler</div>
                <div className="d-flex align-items-center">
                  <div className="search flex-fill me-2 ">
                    <i className="fa fa-search"></i>
                    <input type="text" placeholder="Arama..." />
                  </div>
                  <button
                    onClick={(e) => setOpenFilter(!openFilter)}
                    className="button button-mavi bg-mavi px-2  "
                  >
                    <i
                      style={{ color: "white" }}
                      className=" fa-solid fa-sliders"
                    ></i>
                  </button>
                </div>

                {openFilter ? (
                  <div className="filters gir  mt-2 ">
                    <div className="d-flex">
                      <a
                        href="?type=0"
                        className={
                          url.get("type") == 0
                            ? "bg-mavi text-beyaz  text-decoration-none bg-gri flex-fill fw-500 br text-center py-2 cursor-pointer"
                            : "bg-gri flex-fill fw-500 br text-center py-2  cursor-pointer text-decoration-none text-dark"
                        }
                      >
                        Özel
                      </a>
                      <a
                        href="?type=1"
                        className={
                          url.get("type") == 1
                            ? "bg-mavi text-beyaz text-decoration-none bg-gri flex-fill fw-500 br text-center py-2 ms-2 cursor-pointer"
                            : "bg-gri flex-fill fw-500 br text-center py-2 ms-2 cursor-pointer text-decoration-none text-dark"
                        }
                      >
                        Kurumsal
                      </a>
                    </div>
                    <a
                      className="bg-gri d-block w-100 fw-500 br text-center py-2 mt-1   cursor-pointer text-decoration-none text-dark"
                      href="/?ziyaret=0"
                    >
                      Hiç Ziyaret Edilmeyenler
                    </a>
                    <a
                      href="/"
                      className="button text-center text-decoration-none text-dark button-mavi w-100 d-block mt-1"
                    >
                      Filtreleri Temizle
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {yerler &&
                  yerler.map((val) => {
                    return (
                      <div
                        onClick={(e) => {
                          console.log(location);
                          if (location.pathname != "/") {
                            navigate("/");
                          }
                          e.currentTarget
                            .querySelector("i")
                            .classList.remove("fa-caret-right");
                          e.currentTarget
                            .querySelector("i")
                            .classList.add("fa-caret-down");
                          if (e.currentTarget.classList.contains("acik")) {
                            e.currentTarget.classList.remove("acik");
                          } else {
                            e.currentTarget.classList.add("acik");
                          }

                          e.currentTarget
                            .querySelectorAll(".down-1")
                            .forEach((el) => {
                              el.classList.remove("down-off");
                            });
                        }}
                        className="ilk"
                        key={val.id}
                        title={val.name}
                      >
                        <i className="fa-solid fa-caret-right text-mavi fs-5 me-2"></i>
                        {val.name}
                        {val.ilceler.map((v) => {
                          return (
                            <div
                              onClick={(e) => {
                                e.currentTarget
                                  .querySelectorAll(".down-2")
                                  .forEach((el) => {
                                    if (el.classList.contains("down-off")) {
                                      el.classList.remove("down-off");
                                      e.currentTarget
                                        .querySelector("i")
                                        .classList.remove("fa-caret-right");
                                      e.currentTarget
                                        .querySelector("i")
                                        .classList.add("fa-caret-down");
                                    } else {
                                      el.classList.add("down-off");
                                      e.currentTarget
                                        .querySelector("i")
                                        .classList.add("fa-caret-right");
                                      e.currentTarget
                                        .querySelector("i")
                                        .classList.remove("fa-caret-down");
                                    }
                                  });
                              }}
                              className={
                                v.musteriler.length == 0
                                  ? "fs down down-1 down-off no-mus"
                                  : "fs down down-1 down-off"
                              }
                              key={v.ilce_id}
                            >
                              {v.musteriler.length != 0 ? (
                                <i className="fa-solid fa-caret-right text-mavi fs-5 me-2"></i>
                              ) : (
                                ""
                              )}
                              {v.ilce_adi}

                              <div className="tr-2">
                                {v.musteriler.map((m, i) => {
                                 
                                    return (
                                      <div
                                        key={i}
                                        onClick={(e) =>
                                          openDetail(m.musteri_id)
                                        }
                                        className="sc down down-2 down-off"
                                      >
                                        
                                          <i className="fa-solid fa-user fs-6 text-mavi me-2"></i>
                                      

                                        {m.name}
                                      </div>
                                    );
                                  
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="gir kutu p-0 mt-3">
              <div className="tab-con">
                <div
                  onClick={(e) => setTab(0)}
                  className={tab == 0 ? "tab active" : "tab"}
                >
                  <i className="fa fa-house"></i>Giriş
                </div>
                <div
                  onClick={(e) => {
                    setTab(1);
                  }}
                  className={tab == 1 ? "tab active" : "tab"}
                >
                  <i className="fa-solid fa-circle-half-stroke"></i>Stil
                </div>
              </div>
              {tab == 0 ? (
                <ul className="links">
                  <li data-tip="Ana Sayfa">
                    <Link className="text-white" to={"/"}>
                      {" "}
                      <i className="fa-solid fa-earth-americas"></i>{" "}
                    </Link>
                  </li>

                  <li data-tip="Kamera">
                    <Link className="text-white" to={"/camera"}>
                      {" "}
                      <i className="fa fa-camera"></i>
                    </Link>
                  </li>

                  <li data-tip="Kullanıcılar">
                    <Link className="text-white" to={"/users"}>
                      {" "}
                      <i className="fa-solid fa-user"></i>
                    </Link>
                  </li>

                  <li data-tip="Departmanlar">
                    <Link className="text-white" to={"/departman"}>
                      {" "}
                      <i className="fa-solid fa-layer-group"></i>
                    </Link>
                  </li>
                </ul>
              ) : (
                ""
              )}

              {tab == 1 ? (
                <>
                  <div className="fw-500 ps-2 pt-2 fs-6 lh-1">Harita Rengi</div>
                  <div className="theme-select">
                    <div
                      onClick={(e) => setT(e, 0)}
                      className="active selector"
                    >
                      <img src="/theme-select/standart.png" />
                    </div>
                    <div onClick={(e) => setT(e, 1)} className="selector">
                      <img src="/theme-select/silver.png" />
                    </div>
                    <div onClick={(e) => setT(e, 2)} className="selector">
                      <img src="/theme-select/retro.png" />
                    </div>
                    <div onClick={(e) => setT(e, 3)} className="selector">
                      <img src="/theme-select/dark.png" />
                    </div>

                    <div onClick={(e) => setT(e, 4)} className="selector">
                      <img src="/theme-select/night.png" />
                    </div>

                    <div onClick={(e) => setT(e, 5)} className="selector">
                      <img src="/theme-select/aber.png" />
                    </div>
                  </div>

                  <div className="fw-500 ps-2 pt-2 fs-6 lh-1 mb-2">
                    Harita Rengi
                  </div>
                  <div className="stil-select px-2">
                    <div
                      onClick={() => changeTheme("mor")}
                      className="stil active"
                    ></div>
                    <div
                      onClick={() => changeTheme("yesil")}
                      className="stil"
                    ></div>
                    <div
                      onClick={() => changeTheme("mavi")}
                      className="stil"
                    ></div>
                    <div
                      onClick={() => changeTheme("kirmizi")}
                      className="stil"
                    ></div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
        {mode == 1 ? (
          <div className="gir kutu">
            <div className="d-flex align-items-center justify-content-between">
              <div className="flex-fill">
                <div className="d-none text-white opacity-50 fw-bold fs-5 lh-1 mb-0">
                  İZMİR
                </div>
                <div className="text-mavi lh-1 fw-bold fs-2">
                  {data ? data.name : <div className="sk sk-big"></div>}
                </div>

                <div className="fw-500 lh-1 opacity-50">
                  {data ? sektorler[data.type] : <div className="sk"></div>}
                </div>
              </div>

              <i onClick={() => back()} className="fa fa-arrow-left back"></i>
            </div>

            <div className="row mt-3 gy-3">
              <div className="col-6">
                <div className="text-white fw-bold lh-1">Yetkili</div>
                <div className="text-white opacity-75">
                  {data ? data.ad : <div className="sk"></div>}
                </div>
                <div className="text-white opacity-75 lh-1">
                  {data ? data.mail : <div className="sk"></div>}
                </div>
              </div>

              <div className="col-6">
                <div className="text-white fw-bold lh-1">Sektör</div>
                <div className="text-white opacity-75 lh-1">
                  {data ? data.sektor : <div className="sk"></div>}
                </div>
              </div>

              <div className="col-6">
                <div className="text-white fw-bold lh-1">Cari Kodu</div>
                <div className="text-white opacity-75">
                  {data ? data.cari_kodu : <div className="sk"></div>}
                </div>
              </div>

              <div className="col-6">
                <div className="text-white fw-bold lh-1">Adres</div>
                <div className="text-white opacity-75 lh-1">
                  {data ? data.adres : <div className="sk"></div>}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="text-white fw-bold lh-1">Not</div>
              <div className="text-white opacity-75 lh-1">
                {data ? (
                  data.note ? (
                    data.note
                  ) : (
                    "Not Girilmemiş"
                  )
                ) : (
                  <div className="sk-big sk"></div>
                )}
              </div>
            </div>

            <div className="text-mavi fs-5 fw-bold mt-4">Fotoğraflar</div>
            <div className="row g-2">
              {data ? (
                data.foto.split(",").map((foto, i) => {
                  if (i > (data.foto.split(",").length - 1) / 2) {
                    return (
                      <div key={i} className="col-6 imgasex">
                        <img
                          onContextMenu={(e) => {
                            e.preventDefault();
                          }}
                          onClick={(e) => {
                            imageOpen(
                              `http://192.168.1.80/map-backend/assets/img/musteri/${foto}`
                            );
                          }}
                          src={`http://192.168.1.80/map-backend/assets/img/musteri/${foto}`}
                          className="img-fluid br hover-fade cursor-pointer"
                          style={{ maxHeight: 100, height: 100 }}
                          alt=""
                        />
                      </div>
                    );
                  }
                })
              ) : (
                <>
                  <div className="col-6">
                    <div className="sk sk-full"></div>
                  </div>{" "}
                  <div className="col-6">
                    <div className="sk sk-full"></div>
                  </div>
                </>
              )}
            </div>

            {data && data.grup == 1 && gc ? (
              <> 
              <div className="d-flex justify-content-between my-2 align-items-center">
                <div className="text-mavi fs-5 fw-bold ">Grup</div>
                <a  href={`?grup=${data && data.musteri_id}`} className="button button-mavi px-2 text-decoration-none">Harita</a>
              </div>
                <div className="row">
                  {gc.map((val) => {
                    return (
                      <div className="col-12">
                        <button
                          onClick={(e) => {
                            console.log("s");
                            openDetail(val.musteri_id);
                          }}
                          className="mb-2 button button-mavi fw-bold d-block w-100"
                        >
                          {val.name}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <hr />
              </>
            ) : (
              ""
            )}

            {data ? (
              <div className="row mt-3 gx-2 gy-2">
                <div className="col-12">
                  <a
                    href={`https://www.google.com/maps/ //${data && data.lat},${
                      data && data.lng
                    }/`}
                    target="blank"
                    className="text-decoration-none button button-mavi fw-bold w-100 text-center d-block"
                  >
                    <i className="fa-solid fa-location-arrow"></i> Yol Tarifi Al
                  </a>
                </div>

                <div className="col-12">
                  <button
                    onClick={(e) => setZiyaret(data && data.musteri_id)}
                    className="button button-mavi fw-bold w-100 d-block"
                  >
                    <i className="fa-solid fa-clock-rotate-left"></i> Geçmiş
                    Ziyaretler
                  </button>

                  <button
                    onClick={(e) => {
                      gruplandir(data && data.musteri_id);
                      setMode(3);
                    }}
                    className="button button-mavi mt-2 fw-bold w-100 d-block"
                  >
                    <i className="fa-solid fa-folder "></i> Gruplandır
                  </button>
                </div>
                {localStorage.yetki.split(",")[0] != "false" ? (
                  <div className="col-6">
                    <button className="button button-yesil fw-bold w-100 d-block">
                      <i className="fa-solid fa-edit"></i> Düzenle
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {localStorage.yetki.split(",")[3] != "false" ? (
                  <div className="col-6">
                    <button className="button button-red fw-bold w-100 d-block">
                      <i className="fa-solid fa-trash"></i> Sil
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="row mt-3 gx-2 gy-2">
                <div className="col-12">
                  <div className="sk sk-big"></div>{" "}
                </div>

                <div className="col-12">
                  <div className="sk sk-big"></div>
                </div>

                <div className="col-6">
                  <div className="sk sk-big"></div>
                </div>
                <div className="col-6">
                  <div className="sk sk-big"></div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        {mode == 2 ? (
          <div className="kutu-sec p-2 fw-500 mt-3 gir ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-white fs-5">
                <i className="text-mavi fa-solid fa-clock-rotate-left"></i>{" "}
                Geçmiş Ziyaretler
              </div>
            </div>
            <div className="mt-2">
              <div className="d-flex ">
                <button
                  onClick={(e) => back()}
                  className=" button button-mavi w-100 fw-500 px-2"
                >
                  <i className="fa-solid fa-angle-left"></i> Geri
                </button>

                <button
                  onClick={(e) => openRapor()}
                  className="fw-bold me-2 ms-2 w-100 px-2 button button-yesil "
                >
                  <i className="fa-solid fa-newspaper"></i> Ziyaret Raporu
                </button>

                <button
                  onClick={(e) => zEkle()}
                  className="fw-bold w-100  px-2 button button-mavi "
                >
                  <i className="fa fa-plus"></i> Ekle
                </button>
              </div>
            </div>

            <div className="mt-3"></div>
            <div className="ziyaret">
              <div className="tarih text-mavi">Tarih</div>
              <div className="ziyaretci text-mavi">Ziyaretçi</div>
            </div>

            {ziyaretler &&
              ziyaretler.map((val) => {
                return (
                  <>
                    <div
                      key={val.id}
                      className="ziyaret"
                      onClick={(e) => {
                        setZ(val.id);
                        document.querySelectorAll(".ziyaret").forEach((el) => {
                          el.classList.remove("active");
                        });
                        e.currentTarget.classList.add("active");
                      }}
                    >
                      <div className="tarih">
                        <i className="fa fa-caret-right text-mavi"></i>{" "}
                        {new Date(val.tarih).getDate() +
                          "." +
                          (new Date(val.tarih).getMonth() + 1) +
                          "." +
                          new Date(val.tarih).getFullYear()}
                      </div>
                      <div className="ziyaretci">{val.ziyaretci}</div>
                    </div>
                    <div className="border"></div>
                  </>
                );
              })}
          </div>
        ) : (
          ""
        )}

        {mode == 3 ? (
          <div className="kutu-sec p-2 fw-500 mt-3 gir ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-white fs-5">
                <i className="text-mavi fa-solid fa-folder"></i> Gruplandırma
              </div>

              <button
                onClick={(e) => back()}
                className=" button button-mavi fw-500 px-2"
              >
                <i className="fa-solid fa-angle-left"></i> Geri
              </button>
            </div>

            <div className="mt-3">
              <div className="fw-500">Grup</div>
              <div className="row">
                {gc.map((val) => {
                  return (
                    <div className="col-12">
                      <button
                        onClick={(e) => {
                          //openDetail(val.musteri_id);
                        }}
                        className="mb-2 button button-mavi fw-bold align-items-center px-2 d-flex justify-content-between w-100"
                      >
                        {val.name} <i onClick={e=>{
                          const t = e.currentTarget;
                          e.currentTarget.innerHTML = "<i class='fa fa-spin fa-spinner></i>"
                          axios.post(`/api/update/?col=to_grup&val=0&w=musteri_id&table=musteriler&where=${val.musteri_id}`).then(res=>{
                            console.log(res.data)
                            t.style.display = "none";
                            axios.get("/api/groupContent/" + grup).then((resx) => {
                              setGC(resx.data);
                              console.log(resx.data);
                            });
                          })
                        }} className="fa fa-trash  text-mavi"></i>{" "}
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className="fw-500 mt-3">Ekle</div>
              <div className="search flex-fill  mb-2">
                    <i className="fa fa-search"></i>
                    <input onKeyUp={e=>{
                      axios.get(`/api/search/${localStorage.pid}/?search=${e.target.value}`).then(res=>{
                        setSearchResult(res.data)
                      })
                    }} type="text" placeholder="Arama..." />
              </div>
              {
                searchResult == null ? <div className="text-center mt-2">Bu müşteriyi gruplandırmak için arama yapın</div>
                : searchResult.length == 0 ? <div className="text-center mt-2">Sonuç bulunamadı</div> : searchResult.map(val=>{
                return(<div onClick={e=>{
                  const t = e.currentTarget;
                  e.currentTarget.innerHTML = "<i class='fa fa-spin fa-spinner></i>"
                  axios.post(`/api/update/?col=to_grup&val=${data && data.musteri_id}&w=musteri_id&table=musteriler&where=${val.musteri_id}`).then(res=>{
                    console.log(res.data)
                    t.style.display = "none";
                    axios.get("/api/groupContent/" + grup).then((resx) => {
                      setGC(resx.data);
                      console.log(resx.data);
                    });
                  })
                }} data-tip="Eklemek için tıkla" className="button button-mavi w-100 text-center mb-2">{val.name}</div>)  
                })
                
                }
              
            </div>
          </div>
        ) : (
          ""
        )}
        <ReactTooltip />
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Sidebar;
