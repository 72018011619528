import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import Geocode from "react-geocode";
import axios from "axios";
import { useNavigate} from "react-router-dom";

function Camera() {
  const [konum, setKonum] = useState(null);
  const [adres, setAdres] = useState("");
  const [_adres, _setAdres] = useState("");
  const [yerler,setYerler] = useState(null)
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = useState([]);
  const [step,setStep] = useState(1);
  const [type, setType] = useState("0")
  const [ilceler, setIlceler] = useState(null)

  let navigate = useNavigate();
  Geocode.setApiKey("AIzaSyBSvt9CZo1WtKIl9k8OhL1lZtLGjy-tdIs");

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc((oldArray) => [...oldArray, imageSrc]);
    console.log(imgSrc);
  }, [webcamRef, setImgSrc]);

  useEffect(() => {

    axios.get("/api/getYerler/"+localStorage.getItem("pid")).then(res=>{
      setYerler(res.data)
      console.log(res)
    })

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      console.log(navigator);
      console.log(position);
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;

          console.log(address);
          _setAdres(address);
        },
        (error) => {
          console.error(error);
        }
      );
      setKonum({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  function submit(e){
    e.preventDefault();
    var arr = [];
    document.querySelector("#send").innerHTML = "<i class='fa fa-spinner fa-spin'></i>"
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }
  
  //Usage example:
  for(var i = 0; i < imgSrc.length; i++){
    var file = dataURLtoFile(imgSrc[i],`imagxe${i}.jpg`);
    arr.push(file)
  }



  axios.post("/api/addYer/"+localStorage.pid,{  
    yer: e.target[0].value, 
    ilce: e.target[1].value, 
    marka: e.target[2].value, 
    ad: e.target[3].value, 
    sektor: e.target[4].value, 
    mail: e.target[5].value, 
    adres: e.target[7].value,
    not: e.target[8].value,
    cari_kodu: e.target[9].value,
    type: type,
    lat: konum.lat ,
    lng: konum.lng
  }).then(res=>{
    console.log(res)
    var x = 0;
    imgSrc.forEach((el,i)=>{
      axios.post("/api/addPhotos/"+res.data,{
        image: el
      }).then(res=>{
        console.log("yes")
        x++;
        if(imgSrc.length == x){
          console.log("tamam")
          navigate("/",{replace:true})
        }
      })
    })
  
  })
  }

  return (
    <div className="gir users-popup">
        {step == 1 ? 
      <div className="camera  gir">
        <Webcam ref={webcamRef} screenshotFormat="image/jpeg" />
        <button
          className="button button-mavi fw-bold px-5 mx-auto my-3 d-block"
          onClick={capture}
        >
          <i className="fa-solid fa-camera"></i> Çek
        </button>

        <div className="img-slider">
          {imgSrc.length != 0
            ? imgSrc.map((val, i) => {
                return (
                    <div key={i}>
                  <img  className="simg img-fluid w-100" src={val} />
                 
                  </div >
                );
              })
            : ""}
        </div>
        {imgSrc.length != 0 ? <button onClick={e=>setStep(2)} className="gir button d-block button-yesil fw-bold px-5 mx-auto next-btn"><i className="fa-solid fa-chevron-right"></i> Devam</button> : ""}

        {konum ? (
          <div className="d-none konum text-center text-white my-2">
            {konum.lat + " , " + konum.lng}
          </div>
        ) : (
          ""
        )}
      </div> : "" }

      {step == 2 ? <div className="camera gir">
          <div className="text-mavi fs-5 fw-bold">Bilgileri Gir</div>

          <form onSubmit={e=>submit(e)}>
          <div className="fg mt-3">
            <label htmlFor="ye">Yer</label>
            <select  onChange={e=>{
              axios.get("/api/getIlceler/"+e.target.value).then(res=>{
                setIlceler(res.data)
              }
              )}} id="ye" className="w-100 emr-input d-block text-white">
                 <option  value={"sec"}>Seçiniz</option>

              {
                yerler && yerler.map((val,i)=>{
                  return(
                    <option key={i} value={val.id}>{val.name}</option>
                  )
                })
              }
            </select>
            <label htmlFor="il">İlçe</label>

            <select id="il" className="w-100 emr-input d-block text-white">
              {
                ilceler && ilceler.map((val,i)=>{
                  return(
                    <option key={i} value={val.ilce_id}>{val.ilce_adi}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="fg mt-3">
            <label htmlFor="ma">Marka/Şirket adı</label>
            <input type="text" id="ma" className="emr-input" />
          </div>
          <div className="fg mt-3">
            <label htmlFor="ya">Yetkili Adı</label>
            <input type="text" id="ya" className="emr-input" />
          </div>

          <div className="fg mt-3">
            <label htmlFor="sektor">Sektör</label>
            <input type="text" id="sektor" className="emr-input" />
          </div>

          <div className="fg mt-3">
            <label htmlFor="mail">E-Posta</label>
            <input type="text" id="mail" className="emr-input" />
          </div>
         
          <div className="fg mt-3">
            <label htmlFor="mail">Adres </label>
            <div className="d-flex">
            <input placeholder="Koordinattan Otomatik Çekmek için boş bırakınız" type="text" defaultValue={adres}  id="mail" className="emr-input" />

            <button type="button" onClick={e=>{
              setAdres(_adres);
              e.target.style.display = "none"
            }} className="button button-mavi text-nowrap ms-2 px-3">Otomatik Doldur</button>

            </div>
            <div className="text-white">
              <span className="text-mavi">Koordinatlar:</span> {konum.lat }, {konum.lng}
            </div>
          </div>

          <div className="fg mt-3">
            <label htmlFor="not">Not</label>
            <input type="text" id="not" className="emr-input" />
           
          </div>

          <div className="fg mt-3">
            <label htmlFor="ck">Cari Kodu</label>
            <input type="text" id="ck" className="emr-input" />
          </div>

          <div className="fg mt-3">
            <label>Tür</label>
              <div className="d-flex">
            <div class="form-check">
              <input
                onChange={(e) => {
                  setType("0")
                  console.log(e.target.value);
                }}
                checked={type == "0" ? true : false} 
                style={{ width: 20 }}
                class="form-check-input"
                value={"ozel"}
                type="radio"
                name="flexRadioDisabled"
                id="flexRadioDisabled"
               
              />
              <label class="form-check-label" htmlFor="flexRadioDisabled">
                Özel
              </label>
            </div>
            <div class="ms-3 form-check">
              <input
                 onChange={(e) => {
                  setType("1")
                  console.log(e.target.value);
                }}
                checked={type == "1" ? true : false} 
                style={{ width: 20 }}
                class="form-check-input"
                value={"kurumsal"}
                type="radio"
                name="flexRadioDisabled"
                id="flexRadioCheckedDisabled2"
              />
              <label
                class="form-check-label"
                htmlFor="flexRadioCheckedDisabled2"
              >
                Kurumsal
              </label>
            </div>
            </div>
          </div>

          <button type="submit" id="send" className="button button-mavi d-block w-100 mt-2"><i className="fa fa-plus"></i> Müşteri Ekle</button>
          </form>
      </div> : ""}
    </div>
  );
}

export default Camera;
