import axios from 'axios';
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

function Login() {
  let navigate = useNavigate();

  
  function submit(e){
    e.preventDefault();
    console.log(e.target[2])
    const t = e.target[2]
    t.innerHTML = "Başarılı"
    document.querySelector(".hata").classList.add("d-none")
    e.target[2].innerHTML = "<i class='fa fa-spin fa-spinner'></i>"
    axios.post("/api/login",{
      nick:e.target[0].value,
      pass:e.target[1].value,
    }).then(res=>{
      console.log(res)
      if(res)
      if("mesaj" in res.data){
        document.querySelector(".hata").classList.remove("d-none")
      }else{
        localStorage.reel_id = res.data[0].id
        localStorage.pid = res.data[0].pid
        localStorage.type = res.data[0].type
        localStorage.yetki = res.data[0].yetki
        localStorage.isim = res.data[0].isim
        t.innerHTML = '<i class="fa-solid fa-check"></i>'
        window.location = "/"
      }
    })
  }
  return (
    <div className='login-page'>
      <div className="login-form shadow-sm me-5">
      <form onSubmit={e=>submit(e)}>
        <h5 className='text-mavi text-center'>Giriş Yap</h5>
      <div class="fg mb-3">
        <label >Kullanıcı Adı</label>
          <input type="text" class="emr-input" id="floatingInput" placeholder=""/>
      </div>
      <div class="fg mb-3">
        <label >Şifre</label>
          <input type="password" class="emr-input" id="floatingInput" placeholder=""/>
      </div>
      <div className="hata text-danger d-none">Hatalı Giriş Bilgileri</div>
      <button className="button button-mavi d-block w-100 fw-500 ">Giriş Yap</button>
      </form>
      </div>

      <div className="world ms-5">
        <video  autoPlay muted loop>
          <source src='/world.mp4' />
        </video>
      </div>
    </div>
  )
}

export default Login