import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

function Users() {
  const [mode, setMode] = useState(0);
  const [checkedState, setCheckedState] = useState(new Array(5).fill(false));
  const [users, setUsers] = useState(null);
  const [edit, setEdit] = useState(null);
  const [usr, setUsr] = useState(null);
  const [file, setFile] = useState(null);
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const [depList, setdepList] = useState(null);
  const [type, setType] = useState("0")
  useEffect(() => {
    if (usr != null) {
      const s = [];
      s[0] = usr.yetki.split(",")[0] == "true" ? true : false;
      s[1] = usr.yetki.split(",")[1] == "true" ? true : false;
      s[2] = usr.yetki.split(",")[2] == "true" ? true : false;
      s[3] = usr.yetki.split(",")[3] == "true" ? true : false;
      s[4] = usr.yetki.split(",")[4] == "true" ? true : false;
      setCheckedState(s);
    }
  }, [usr]);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  function update(e) {
    e.preventDefault();
    const t = document.querySelector("#send");
    t.innerHTML = "<i class='fa fa-spinner fa-spin'></i>";
    const s  = [];
    const c = document.querySelectorAll(".form-check-input")
    c.forEach((el,i)=>{
        s[i] = el.checked
    })
    console.log("ss")
    console.log(s.join(","))
    axios
      .post(`/api/editUser/${edit}`, {
        isim: e.target[0].value,
        pozisyon: e.target[1].value,
        nick: e.target[2].value,
        pass: e.target[3].value,
        mail: e.target[4].value,
        yetkiler: s.join(","),
        type:type
      })
      .then((res) => {
        setMode(0);
        axios.get("/api/getUsers/" + localStorage.pid).then((res2) => {
          setUsers(res2.data);
        });
      });
  }

  useEffect(() => {
    axios.get("/api/getUsers/" + localStorage.pid).then((res) => {
      setUsers(res.data);
    });

    axios.get("/api/departmanList/" + localStorage.pid).then((res) => {
      setdepList(res.data);
    });
  }, []);

  function submit(e) {
    e.preventDefault();
   
    const t = document.querySelector("#send");
    t.innerHTML = "<i class='fa fa-spinner fa-spin'></i>";
    const s  = [];
    const c = document.querySelectorAll(".form-check-input")
    c.forEach((el,i)=>{
        s[i] = el.checked
    })
    console.log("ss")
    console.log(s.join(","))
    axios
      .post(`/api/addUser/${localStorage.pid}`, {
        isim: e.target[0].value,
        pozisyon: e.target[1].value,
        nick: e.target[2].value,
        pass: e.target[3].value,
        yetkiler: s.join(","),
        image: file,
        mail: e.target[4].value,
        type:type
      })
      .then((res) => {
        setMode(0);
        console.log(res);
        axios.get("/api/getUsers/" + localStorage.pid).then((res2) => {
          setUsers(res2.data);
        });
      });
  }
  return (
    <div className="gir users-popup">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column justify-content-between">
          <div className="text-mavi fw-500 fs-4 lh-1">Kullanıcılar</div>
          <p className="text-white">
            Kullanıcılarınızı düzenleme ve ekleme paneli
          </p>
        </div>

        {mode == 0 ? (
          <button
            onClick={(e) => {
              setMode(1);
              setUsr(null);
              setEdit(null);
            }}
            className="button button-mavi fw-bold text-black px-3 fs-4"
          >
            <i className="fa fa-plus"></i> Personel Ekle
          </button>
        ) : (
          ""
        )}
      </div>

      {mode == 0 ? (
        <div className="gir row g-4">
          {users &&
            users.map((val) => {
              return (
                <div
                  onClick={() => {
                    setProfile(val.id);
                    axios.get("/api/profile/" + val.id).then((res) => {
                      console.log(res.data[0]);
                      console.log(res.data[0].yetki);
                      setUser(res.data[0]);
                      setMode(2);
                    });
                  }}
                  key={val.id}
                  className="col-lg-4"
                >
                  <div className="user position-relative">
                    <div className="position-absolute user-hover">
                      <div className="ust-sag">
                        <div className="mini-btn bg-red ">
                          <i className="fa-solid fa-trash-can text-white"></i>
                        </div>
                        <div
                          onClick={(e) => {
                            e.currentTarget.innerHTML =
                              "<i class='fa fa-spinner fa-spin'></i>";
                            axios.get("/api/getUser/" + val.id).then((res) => {
                              setUsr(res.data[0]);
                              setMode(1);
                              setEdit(val.id);
                              e.currentTarget.innerHTML =
                                '<i class="fa  fa-pen text-white"></i>';
                            });
                          }}
                          className="mini-btn ms-2 bg-yesil "
                        >
                          <i className="fa  fa-pen text-white"></i>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 pb-1">
                      <div
                        className="br-full"
                        style={{ width: 120, height: 120 }}
                      >
                        <img
                          onError={(e) => {
                            e.target.src =
                              axios.defaults.baseURL +
                              "/assets/img/user/default.png";
                          }}
                          src={
                            axios.defaults.baseURL +
                            "/assets/img/user/" +
                            val.pp
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="isim fs-4 lh-1 fw-500 text-mavi">
                      {val.isim}
                    </div>
                    <div className="isim fs-6  mb-2 fw-500 text-white">
                      {val.pozisyon}
                    </div>
                    <div className="yetki text-white opacity-75 pb-4"></div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        ""
      )}
      {mode == 1 ? (
        <form
          onSubmit={(e) => (usr == null ? submit(e) : update(e))}
          className="gir"
        >
          <div className="fg mb-3 w-25">
            <label>İsim Soyisim</label>
            <input
              defaultValue={usr && usr.isim}
              type="text"
              className="emr-input"
            />
          </div>

          <div className="fg w-25">
            <label>Pozisyon</label>
            <input
              defaultValue={usr && usr.pozisyon}
              type="text"
              className="emr-input"
            />
          </div>

          <div className="fg w-25 mt-3">
            <label>Kullanıcı Adı</label>
            <input
              defaultValue={usr && usr.nick}
              type="text"
              className="emr-input"
            />
          </div>

          <div className="fg w-25">
            <label>Şifre</label>
            <input
              defaultValue={usr && usr.pass}
              type="text"
              className="emr-input"
            />
          </div>

          <div className="fg w-25">
            <label>Email</label>
            <input
              defaultValue={usr && usr.mail}
              type="text"
              className="emr-input"
            />
          </div>

          <div className="fg w-25">
            <label>Departman</label>
            <select onChange={(e) => { 
                      console.log("s")
                      if(e.target.value != "sec"){
                      const y = e.target.value.split(",")
                      
                      const c = document.querySelectorAll(".form-check-input");
                      c.forEach((el,i)=>{
                        el.checked = y[i] == "true" ? true : false

                      })
                    }
                    }} className="emr-input d-block">
                      <option value="sec">Seçiniz</option>
              {depList &&
                depList.map((val) => {
                  return (
                    <option value={val.yetki} key={val.departman_id}>
                      {val.isim}
                    </option>
                  );
                })}
            </select>
          </div>

        

          <div className="fg w-25">
            <label>Fotoğraf</label>
            <input
              onChange={(e) => {
                let files = e.target.files;
                let fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);

                fileReader.onload = (event) => {
                  setFile(event.target.result);
                };
              }}
              type="file"
              className="emr-input"
            />
          </div>


          <div className="yetkiler">
            <div className="text-mavi fw-500 fs-5 mt-3">Yetkiler</div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Ekleme"
                defaultChecked={
                  usr == null
                    ? false
                    : usr.yetki.split(",")[0] == "true"
                    ? true
                    : false
                }
                onChange={() => handleOnChange(0)}
              />
              <label className="form-check-label text-white">
                Müşteri Ekleme
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
                defaultChecked={
                  usr == null
                    ? false
                    : usr.yetki.split(",")[1] == "true"
                    ? true
                    : false
                }
                onChange={() => handleOnChange(1)}
              />
              <label className="form-check-label text-white">
                Müşteri Düzenleme
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
                defaultChecked={
                  usr == null
                    ? false
                    : usr.yetki.split(",")[2] == "true"
                    ? true
                    : false
                }
                onChange={() => handleOnChange(2)}
              />
              <label className="form-check-label text-white">
                Geçmiş Ziyaretler Düzenleme
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
                defaultChecked={
                  usr == null
                    ? false
                    : usr.yetki.split(",")[3] == "true"
                    ? true
                    : false
                }
                onChange={() => handleOnChange(3)}
              />
              <label className="form-check-label text-white">
                Geçmiş Ziyaretler Silme
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="Musteri Duzenleme"
                defaultChecked={
                  usr == null
                    ? false
                    : usr.yetki.split(",")[4] == "true"
                    ? true
                    : false
                }
                onChange={() => handleOnChange(4)}
              />
              <label className="form-check-label text-white">
                Geçmiş Ziyaretler Ekleme
              </label>
            </div>

            <div className="d-flex mt-3 w-25">
              <button
                id="send"
                className="flex-fill me-2 button button-mavi fw-bold px-3"
              >
                <i className="fa fa-plus"></i> {!usr ? "Ekle" : "Güncelle"}
              </button>

              <button
                onClick={(e) => {
                  setMode(0);
                  setEdit(null);
                  setUsr(null);
                }}
                className="button button-gri fw-bold px-3"
              >
                <i className="fa-solid fa-ban"></i> İptal
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}
      {mode == 2 && user ? (
        <div className="gir">
          <div className="row">
            <div className="col-lg-6">
              <div className="br profile-main-box p-1 bg-gri position-relative">
                <img
                  onError={(e) => {
                    e.target.src =
                      axios.defaults.baseURL + "/assets/img/user/default.png";
                  }}
                  src={axios.defaults.baseURL + "/assets/img/user/" + user.pp}
                  alt=""
                />
                <div className="user-ml">
                  <div className="fw-500 fs-5">{user.isim}</div>
                  <div className="fw-500 lh-1 opacity-75">{user.pozisyon}</div>
                  <div className="fw-500 text-mavi lh-1 opacity-75">
                    {user.mail}
                  </div>
                </div>
              </div>
              <div className="mt-3 row">
                <div className="yetkiler">
                  <div className="fw-500 fs-5">Yetkiler</div>
                  {user.yetki ? (
                    <div className="d-flex flex-wrap">
                      {user.yetki.split(",")[0] == "true" ? (
                        <div className="bg-gri br p-2 me-1 mb-1">
                          Müşteri Ekleme
                        </div>
                      ) : (
                        ""
                      )}

                      {user.yetki.split(",")[1] == "true" ? (
                        <div className="bg-gri br p-2 me-1 mb-1">
                          Müşteri Düzenleme
                        </div>
                      ) : (
                        ""
                      )}

                      {user.yetki.split(",")[2] == "true" ? (
                        <div className="bg-gri br p-2 me-1 mb-1">
                          Geçmiş Ziyaretler Düzenleme
                        </div>
                      ) : (
                        ""
                      )}

                      {user.yetki.split(",")[3] == "true" ? (
                        <div className="bg-gri br p-2 me-1 mb-1">
                          Geçmiş Ziyaretler Silme
                        </div>
                      ) : (
                        ""
                      )}

                      {user.yetki.split(",")[4] == "true" ? (
                        <div className="bg-gri br p-2 me-1 mb-1">
                          Geçmiş Ziyaretler Ekleme
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="fw-500 fs-5">Ziyaretler</div>
              <label className="fw-bold text-mavi mb-4">
                Son Ziyaret :{" "}
                <span className="text-dark opacity-75 fw-normal">
                  {new Date(user.son).getDate() +
                    "." +
                    (new Date(user.son).getMonth() + 1) +
                    "." +
                    new Date(user.son).getFullYear()}
                </span>
              </label>
              {user.ziyaretler.map((val, i) => {
                return (
                  <div className="bg-gri p-2 pt-0 br mb-3">
                    <div className="text-mavi fw-bold fs-4">
                      {val.sirket_adi}
                    </div>
                    <div className="lh-1 fw-500">
                      {val.toplam} Kez Ziyaret Edilmiş
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default Users;
